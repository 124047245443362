import * as amplitude from '@amplitude/analytics-browser';

export const initAnalytics = (apiKey, userData) => {
    amplitude.init(apiKey, userData?.idTokenClaims?.primaryEmail, {
        defaultTracking: true
    });
};

export const analyticsTrackEvent = (eventName, eventProperties) => {
    amplitude.track(eventName, eventProperties);
};

export const analyticsTrackClickEvent = (eventName, eventProperties) => {
    amplitude.track(eventName, eventProperties);
};

export const analyticsSetUserId = (userId) => {
    amplitude.setUserId(userId);
};

export const analyticsReset = () => {
    amplitude.reset();
};

export const getMappedPathName = (path) => {
    if (!path || (typeof path !== 'string')) return;

    let mappedPathName = path == '/' ? path.toLowerCase() : path.replace(/\//, '')?.toLowerCase().replace(/-/g, '_');
    console.log(mappedPathName);
    switch (mappedPathName) {
        case '/':
            return 'home';
        case 'manageprofile':
            return 'profile_manage';
            case 'membersuppport':
                return 'support';
        default:
            return mappedPathName;
    }
};

export const getMappedClickEventPathName = (path) => {
    if (!path || (typeof path !== 'string')) return;

    let mappedPathName = path == '/' ? path.toLowerCase() : path.replace(/\//, '')?.toLowerCase().replace(/-/g, '_');
    switch (mappedPathName) {
        case '/':
            return 'home';
        case 'manageprofile':
            return 'profile_manage';
        case 'https:/dellchildrenstestweb.healthsparq.com/healthsparq/public/#/one/insurercode=agdchp_i&brandcode=agdchp':
            return 'finddoc';
        case 'benefits_overview':
            return 'medicalbenefits';
        case 'benefits_whatscovered':
            return 'whatscovered';
        case 'benefits_extrabenefits':
            return 'extrabenefits';
        case 'benefits_coveredmembers':
            return 'coveredmembers';
        case 'membersuppport':
            return 'moresupport';
        case 'benefits_extrabenefits#box_0':
            return 'benefits_overview_valueadded';
        case 'benefits_extrabenefits#box_3':
            return 'benefits_overview_neighborhood';
        case 'benefits_extrabenefits#box_4':
            return 'benefits_overview_safetygear';
        case 'benefits_extrabenefits#box_1':
            return 'benefits_overview_vision';
        case 'benefits_extrabenefits#box_10':
            return 'benefits_overview_transportation';
        case 'benefits_extrabenefits#box_7':
            return 'benefits_overview_mental';
        case 'chipandstar':
            return 'chipandstar';
        case 'benefitsforpregnant':
            return 'benefits_whatscovered_pregnant';
        case 'pharmacy':
            return 'benefits_whatscovered_pharmacy';
        case 'behavioralhealth':
            return 'benefits_whatscovered_behavioral';
        case 'transportationbenefits':
            return 'benefits_whatscovered_transportation';
        case 'questions':
            return 'benefits_whatscovered_questions';
        case 'valueaddedservices':
            return 'benefits_extrabenefits_valueadded';
        case 'visionservices':
            return 'benefits_extrabenefits_vision';
        case 'dentalservices':
            return 'benefits_extrabenefits_dental';
        case 'neighborhoodcenter':
            return 'benefits_extrabenefits_neighborhood';
        case 'carandbikesafety':
            return 'benefits_extrabenefits_carandbike';
        case 'sportsschool':
            return 'benefits_extrabenefits_sports';
        case 'diseasemanagement':
            return 'benefits_extrabenefits_asthma';
        case 'mentalwellbeing':
            return 'benefits_extrabenefits_mental';
        case 'education':
            return 'education';
        case 'nursehelpline':
            return 'nursehelpline';
        case 'transportation':
            return 'transportation';
        case 'socialservices':
            return 'socialservices';
        case 'spiritualcare':
            return 'spiritualcare';
            case 'profiletestlogout':
            return 'profile_logout_clicked';
        default:
            return mappedPathName;
    }
};

export const getMappedEventCategory = (eventName) => {
    switch (eventName) {
        case 'home_viewed':
            return 'Home Page Pathing';
        case 'profile_manage_viewed':
            return 'Global Pathing';
        case 'benefits_overview_viewed':
            return 'Plan Benefits Pathing';
        case 'benefits_whatscovered_viewed':
            return 'Plan Benefits Pathing';
        case 'benefits_extrabenefits_viewed':
            return 'Plan Benefits Pathing';
        case 'idcards_viewed':
            return 'Plan Benefits Pathing';
        case 'support_viewed':
            return 'Support Pathing';
        default:
            return;
    }
};